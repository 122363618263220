import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import firebase, {auth} from '../lib/firebase';
import Avatar from '@material-ui/core/Avatar';
import Upload from './Upload';
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Wrapper = styled.div`
    display: flex;
`;

const provider = new firebase.auth.GoogleAuthProvider();

export default function MenuAppBar({data, setData, setUserData, userData, token, admin, setAdmin}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const signInWithGoogle = () => {
    setAnchorEl(null);
    auth.signInWithPopup(provider);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await firebase.auth().signOut();
    setUserData(null);
    setAnchorEl(null);
    setAdmin(false);
  }

  console.log('token', token)
  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Home
          </Typography>
            <Wrapper>
              {admin && <Upload  data={data} setData={setData} token={token}/>}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {userData ? <Avatar alt={userData.displayName} src={userData.photoURL} /> : <AccountCircle />}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
              >
                {!userData && <MenuItem onClick={signInWithGoogle}>SignIn with Google</MenuItem>}
                {userData && <MenuItem onClick={signOut}>Signout</MenuItem>}
              </Menu>
            </Wrapper>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}