import React from 'react';
import './App.css';
import Home from './Pages/Home';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {cyan, pink } from '@material-ui/core/colors';

function App() {

  const theme = createMuiTheme({
    palette : {
      primary: cyan,
      secondary: pink
    },
  });

  return <ThemeProvider theme={theme}><Home /></ThemeProvider>;
}

export default App;
