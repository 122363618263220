// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDj0h_zZaH3rBavWM1DZF414DpT0fQ9Zls",
    authDomain: "laasya-ca864.firebaseapp.com",
    projectId: "laasya-ca864",
    appId: "1:461715054661:web:71e709a84081a054c1432c"
  };

!firebase.apps.length && firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;