import React, { useState, useEffect, Fragment } from 'react';
import AppBar from '../Components/AppBar';
import Reveal from 'react-reveal/Reveal';
import styled from 'styled-components';
import ImageDetails from '../Components/ImageDetails';
import firebase from '../lib/firebase';

const ReavelBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`;

const ImageWrapper = styled.div`
  height: 30rem;

  &:hover {
    cursor: pointer
  }
`;

const Home = () => {
  const [data, setData] = useState([]);
  const [token, setToken] = useState();
  const [userData, setUserData] = React.useState();

  const [tokenPromise, setTokenPromise] = useState();
  const [admin, setAdmin] = useState(false);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);



  useEffect(() => {


    firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {
        if (!userData || userData.email !== user.email) {
          setUserData(user);
          if (user.email === 'narendravarmaalluri@gmail.com' || user.email === 'amrutha.90990@gmail.com') {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
    
          if(!tokenPromise) {
           const idToken = await firebase.auth().currentUser.getIdToken(true);
           setToken(idToken)
          }
        }
      }
    });
    
    }, [token, tokenPromise, userData]);

  useEffect(() => {
    const fetchData = async () => {
      const options =  {};
      const response = await fetch('/api/images', options);
      const body = await response.json()
      setData(body.result);
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setImage(null);
  }

  const openImageDetails = (image, index) => {
    setImage(image);
    setOpen(true);
    setSelectedImageIndex(index);
  }

  const deleteFile = async () => {

    if(admin)  {
      const authHeader = 'Bearer ' + token;
      const options =  token ? {
        method: 'DELETE',
        headers: {
          authorization: authHeader
        },
      } : {};
      const response = await fetch('/api/images?id=' + data[selectedImageIndex].id, options);
      await response.json()
    }

    data.splice(selectedImageIndex, 1);
    setData([...data]);
    setOpen(false);
    setImage(null);
    setSelectedImageIndex(-1);

  }
  console.log('admin', admin)

  return <Fragment>
    <AppBar data={data} setData={setData} userData={userData} setUserData={setUserData} tokenPromise={tokenPromise} setTokenPromise={setTokenPromise} admin={admin} setAdmin={setAdmin} setToken={setToken} token={token} />
    {data.map((image, index) => <ReavelBox>
      <Reveal effect="fadeInUp">
        <ImageWrapper><img height="100%" alt="" src={image.url} onClick={() => openImageDetails(image, index)} /></ImageWrapper>
      </Reveal>
    </ReavelBox>)}
      {image && <ImageDetails deleteFile={deleteFile} admin={admin} token={token} open={open} handleClose={handleClose} image={image}/>}
  </Fragment>
}

export default Home;