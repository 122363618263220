import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({name, deleteFile, open, handleClose, image}) {
  const classes = useStyles();

  return (
    <div>
     
      <Dialog fullWidth={true} maxWidth = {'sm'} open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
           {name &&  <Typography variant="h6" className={classes.title}>
              {image.id}
            </Typography> }
            <Button autoFocus color="inherit" onClick={deleteFile}>
            Delete
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
            <img width="100%" alt="" src={image.url} />
        </DialogContent>
       
      </Dialog>
    </div>
  );
}