import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

const Wrapper = styled.div`
    margin: 1rem;
`;

const FileList = styled.div`
`;

const File = styled.div`
    margin-top: 1rem;
`;

export default function FormDialog({data, setData, token}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [inProgress, setInProgress] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleFile = ({ target }) => {
        setFiles(target.files);
    }

    const uploadFiles= () => {
        const formData = new FormData()

        Array.from(files).forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        const authHeader = 'Bearer ' + token;
        setInProgress(true);
        fetch('/api/uploadFiles', {
                method: 'POST',
                body: formData,
                headers: {
                    authorization: authHeader
                  }
            }).then(response => response.json())
            .then(success => {
                console.log('success.ids', success.ids);
                setData([ ...success.ids, ...data,])
                setInProgress(false);
                handleClose();
            })
            .catch(error => console.log(error));
    }

    console.log(files)

    return (
        <Wrapper>
            {!inProgress && <Button variant="outlined" color="black" onClick={handleClickOpen}>
                Upload
            </Button>}
            {inProgress && <Button disabled variant="outlined" color="black" >
                Upload
            </Button>}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogContent>
                    <input
                        onChange={handleFile}
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="files"
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Select Files
                        </Button>
                    </label>
                    <FileList>
                    {Array.from(files).map(file => <File>{file.name}</File>)}
                    </FileList>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={uploadFiles} color="primary">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </Wrapper>
    );
}
